<template>
	<van-popup v-model="popupShow">
		<el-input :placeholder="placeholder" v-model="inputVal" class="input-define">
			<el-button slot="append" @click="defineClick">确定</el-button>
		</el-input>
		<i class="el-icon-close" @click="closeClick"></i>
	</van-popup>
</template>

<script>
	export default {
		props: {
			placeholder: {
				type: String,
				required: true
			},
		},
		data() {
			return {
				inputVal: '',
				popupShow: false,
                id: '',
                type: ''
			}
		},
		methods: {
			defineClick(){
				if(this.inputVal){
					this.$emit('defineClick',this.id, this.type, this.inputVal)
					this.popupShow = false
				}else{
					this.$toast.fail(this.placeholder);
				}

			},
			show(id, type, text) {
                this.id = id
                this.type = type
                this.inputVal = text
				this.popupShow = true
			},
			closeClick(){
				this.popupShow = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.van-popup {
		width: 90%;
		display: flex;
		border: 2px solid #2388FF;

		::v-deep.input-define {
			.el-input__inner {
				border-radius: 0px;
			}

			.el-button {
				border-radius: 0px;
				background: #2388FF;
				border: 1px solid #2388FF;
				color: #FFF;
			}
		}

		.el-icon-close {
			display: flex;
			align-items: center;
			padding: 0 8px;
			color: #2388FF;
			font-size: 20px;
			cursor: pointer;
		}
	}
</style>
